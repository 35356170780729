.faeye-icon {
  margin-right: 9.25px !important;
}

.error-message {
  height: 1em;
  position: absolute;
  font-size: 0.8em;
}

.icon-padding {
  padding-right: 34px !important;
}

.input-fields-error-color {
  background-color: rgba(201, 31, 37, 0.1) !important;
}

.icon-margin-for-safari {
  -webkit-margin-start: -10px;
}

.form-control:disabled {
  background-color: white !important;
  color: var(--muted);
}

.disable-dark-input:disabled {
  color: var(--info) !important;
}
