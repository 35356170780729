.form-control::placeholder {
  font-family: Lato;
  font-style: normal;
  font-weight: lighter;
  font-size: 18px;
  line-height: 24px;
  color: #ada6a6;
}

.form-control {
  border: 1px solid #141414;
}
