.profile-class .form-control {
  font-family: Lato;
  font-size: 20px;
}

.profile-arrow-button {
  background-color: rgba(201, 31, 37, 0.1) !important;
  color: #c91f25;
  padding: 6px;
  border-radius: 4px;
}

.profile-arrow-button-admin {
  background-color: rgba(155, 4, 230, 0.1) !important;
  color: #9b04e6;
  padding: 6px;
  border-radius: 4px;
}
