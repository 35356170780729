.modal-content {
  border-radius: 15px !important;
}

.rhap_container {
  box-shadow: none !important;
}

.rhap_progress-filled {
  background-color: #c91f25 !important;
}

.rhap_progress-indicator {
  width: 0px !important;
  height: 0px !important;
}

.rhap_progress-bar:hover .rhap_progress-filled {
  background-color: red !important;
  height: 140% !important;
}

.rhap_progress-bar:hover .rhap_progress-indicator {
  background: red !important;
  width: 16px !important;
  height: 16px !important;
  top: -5px !important;
}

.rhap_progress-bar:active .rhap_progress-filled {
  background-color: red !important;
  height: 140% !important;
}

.rhap_progress-bar:active .rhap_progress-indicator {
  background: red !important;
  width: 16px !important;
  height: 16px !important;
  top: -5px !important;
}

.rhap_download-progress {
  background-color: #fdb2b2 !important;
}

@media screen and (max-width: 1300px) {
  .forward-back-btn {
    -webkit-margin-before: -2px !important;
  }
}

.player-button:hover {
  color: #c91f25 !important;
}

@media (min-width: 1200px) {
  .container-uxl-player {
    max-width: 1520px !important;
  }
}

.disbale-audio-player-button {
  cursor: initial !important;
}

.disbale-audio-player-button:hover {
  color: #868686 !important;
}
