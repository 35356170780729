.header-customer-list {
  border-bottom: 1px solid #f1f1f1;
  font-weight: 700;
}

@media screen and (min-width: 992px) {
  .customer-list-for-admin {
    background-color: var(--white);
    border-radius: 10px;
  }
}

.progress-bar-short-text {
  width: 2.5em;
  height: 2.5em;
}

.button-style,
.change-password-style {
  font-size: 14px;
  height: 44px;
  font-weight: 700;
  border-radius: 5px;
}

.vertical-divider {
  margin-bottom: 70px;
  margin-right: -85px;
}

@media only screen and (min-width: 768px) {
  .change-password-style {
    width: 180px;
  }
}

.flex-child {
  max-width: 725px !important;
}

.arrow-button-admin {
  background-color: rgba(155, 4, 230, 0.1) !important;
  color: #9b04e6;
  padding: 6px;
  border-radius: 4px;
}

.upload-logo {
  position: absolute;
  margin-top: 90px;
  margin-left: -44px;
  border: 2px solid white;
  border-radius: 50%;
  padding: 6px;
  background-color: #f7f7f8;
}

.upload-logo-customer {
  position: absolute;
  margin-top: 57px;
  margin-left: -44px;
  border: 2px solid white;
  border-radius: 50%;
  padding: 6px;
  background-color: #f7f7f8;
}

.checkbox-label-delivery-medium {
  position: relative;
  top: 3px;
}

.header-button-category {
  width: 150px;
  border-color: #dee2e6 !important;
}

.category-icons-list-admin {
  width: 100px !important;
  height: 100px !important;
}

.package-modify-image-wrapper {
  width: auto !important;
  height: 100px !important;
}

.package-modify-image {
  max-width: 200px !important;
  max-height: 100px !important;
}
