.header-title {
  color: black !important;
}

.header-plus-button {
  width: 26px !important;
  height: 26px !important;
  border-radius: 50% !important;
  text-align: center !important;
  padding: 0px 02px 0px 0px !important;
  color: white !important;
}

.header-img {
  max-height: 60px;
}
