.header-button {
  width: 150px;
  border-color: #dee2e6 !important;
}

.heading-text {
  font-weight: 500;
}

.view-button-circle {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}

.action-buttons {
  width: 32px;
  height: 32px;
  padding: 5px 0px;
  margin-left: 15px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}

.upload-button {
  position: -webkit-sticky !important;
  position: fixed !important;
  display: block !important;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.mobile-view-font {
  font-size: 14px;
}

.custom-menu {
  color: black !important;
  top: 60% !important;
  left: -785% !important;
  width: 350% !important;
}

@media screen and (max-width: 576px) {
  .custom-menu {
    top: 100% !important;
    left: -600% !important;
    width: 350% !important;
  }
}

.customer-category-card-mediums {
  font-size: 12px;
}

.flex-child-short-text {
  max-width: 340px !important;
}

@media screen and (min-width: 1400px) {
  .flex-child-short-text {
    max-width: 500px !important;
  }
}

.flex-child-audio {
  max-width: 100px !important;
}

@media screen and (min-width: 1400px) {
  .flex-child-audio {
    max-width: 140px !important;
  }
}

@media screen and (min-width: 1700px) {
  .flex-child-audio {
    max-width: 200px !important;
  }
}

.toggle-buttons-category-inactive {
  border-color: var(--light) !important;
  color: var(--primary) !important;
}

.date-time-picker-icon {
  margin-left: -28px !important;
  margin-top: 11px !important;
}

@media screen and (max-width: 576px) {
  .date-time-picker-audio-parent > .rdtOpen > .rdtPicker {
    margin-left: 70px !important;
  }
}

@media screen and (min-width: 992px) {
  .date-time-picker-audio-parent > .rdtOpen > .rdtPicker {
    margin-left: 100px !important;
  }
}

@media screen and (max-width: 576px) {
  .date-time-picker-short-text-parent > .rdtOpen > .rdtPicker {
    margin-left: 15% !important;
  }
}

@media screen and (min-width: 768px) {
  .date-time-picker-short-text-parent > .rdtOpen > .rdtPicker {
    margin-left: 5% !important;
  }
}

@media screen and (min-width: 992px) {
  .date-time-picker-short-text-parent > .rdtOpen > .rdtPicker {
    margin-left: 15% !important;
  }
}

@media screen and (min-width: 1400px) {
  .date-time-picker-short-text-parent > .rdtOpen > .rdtPicker {
    margin-left: 43% !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .service-modal-dialog > .modal-dialog {
    max-width: 569px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .service-modal-dialog > .modal-dialog {
    max-width: 600px !important;
  }
}

.short-text-arrow-button {
  background-color: rgba(201, 31, 37, 0.1) !important;
  color: #c91f25;
  padding: 6px;
  border-radius: 4px;
}

.progress-bar-audio {
  width: 2.5em;
  height: 2.5em;
}

.action-buttons-upload:disabled {
  color: #f1f1f1 !important;
}
