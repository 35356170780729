.admin-otp-box {
  background-color: #f7f7f8;
  width: 90% !important;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-size: 30px;
  margin-right: 15px;
  padding-bottom: 10px;
  box-shadow: none;
  border-bottom: 1px solid black;
  border-radius: 0px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .admin-otp-box {
    font-size: 20px;
    margin-right: 6px;
    margin-left: 6px;
    padding-left: 2px;
    padding-right: 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin-otp-box {
    font-size: 25px;
    margin-right: 10px;
    margin-left: 10px;
  }
}
