.form-text-class {
  color: white;
}

.footer {
  background-color: black !important;
}

.form-text-class {
  font-size: 40px;
  font-weight: 500;
}

.footer-logo-container {
  text-align: center;
  color: white;
  font-size: 20px;
}

.details-row-class {
  font-size: 15px;
  font-weight: bold;
}

.copyright-details {
  font-size: 14px;
  font-weight: 500;
  text-align: start;
  color: white;
}

.privacy-policy-details,
.cookie-policy-details {
  text-align: right;
  color: white;
}

.footer-logo {
  padding-left: 50px;
}

@media (min-width: 360px) {
  .copyright-details {
    padding-top: 15px;
    text-align: center;
  }

  .privacy-cookie-container {
    margin-bottom: 10px;
  }

  .privacy-policy-details {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .cookie-policy-details {
    font-size: 14px;
    font-weight: 500;
    text-align: start;
  }

  .footer-logo img {
    width: 250px;
    height: 75px;
  }

  .logo-and-social-footer {
    text-align: center;
    padding-top: 50px;
  }

  .footer-logo-container {
    font-size: 28px;
  }

  .hr-class {
    width: 93%;
    border: 1px solid #222222;
  }
}

@media (min-width: 768px) {
  .copyright-details {
    padding-top: 0px;
    text-align: start;
  }

  .privacy-policy-details {
    text-align: end;
    color: white;
  }

  .footer-logo img {
    width: 200;
    height: 61px;
  }

  .logo-and-social-footer {
    text-align: start;
  }

  .footer-socials {
    line-height: 61px !important;
    margin-right: -20px;
    width: 100%;
  }

  .footer-terms {
    text-align: right;
  }

  .footer-logo-container {
    font-size: 20px;
  }

  .hr-class {
    width: 100%;
  }
}

@media (min-width: 1366px) {
  .copyright-details {
    padding-top: 0px;
    text-align: start;
  }

  .logo-and-social-footer {
    padding-top: 80px;
  }

  .footer-logo img {
    width: 200px;
    height: 61px;
  }

  .footer-terms {
    text-align: center;
    padding-left: 55px;
  }
  .privacy-cookie-container {
    padding-left: 0px;
    padding-right: 60px;
  }

  .privacy-policy-details,
  .cookie-policy-details {
    text-align: end;
    color: white;
  }

  .footer-logo-container {
    padding-left: 0px;
  }

  .hr-class {
    width: 100%;
  }
}

.footer-socials a {
  color: aliceblue;
}
