.header {
  border-bottom: 1px solid #f1f1f1;
  font-weight: 700;
}

@media screen and (min-width: 992px) {
  .token-transaction-list-for-admin {
    background-color: var(--white);
    border-radius: 10px;
  }
}

.date-filter-label-token-transction {
  background-color: rgba(155, 4, 230, 0.1) !important;
  border-radius: 45px;
  padding: 7px 12px;
}
