@media (min-width: 992px) {
  .col-lg-navbar {
    flex: 0 0 21% !important;
    width: 21% !important;
    max-width: 288px !important;
  }
}

@media (min-width: 992px) {
  .col-lg-body {
    flex: 1 0 79% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 1920px) {
  .container-uxl-menubar {
    max-width: 1920px !important;
  }
}

.arrow-button {
  position: fixed;
  z-index: 1010;
  top: 4%;
  left: 73%;
  background-color: white;
  border-radius: 50px;
}

.mobile-flex {
  flex-basis: auto !important;
}

.custom-dropdown-menu {
  position: fixed;
  z-index: 1010;
  bottom: 20px;
  margin-left: 20px;
  width: 19%;
  border-radius: 8px !important;
  max-width: 245px !important;
}

.custom-menu-menubar:hover {
  color: #c91f25;
  cursor: pointer;
}

.admin-custom-menu:hover {
  color: #9b04e6;
  cursor: pointer;
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .custom-dropdown-menu {
    width: 11%;
  }
}

@media only screen and (max-width: 576px) {
  .custom-dropdown-menu {
    width: 70%;
    max-width: 400px !important;
  }
}
