@media (min-width: 360px) {
  .navbar-brand-class img {
    width: 116px;
    height: 35px;
    /* background-color: white; */
  }
}

@media (min-width: 768px) {
  .navbar-brand-class img {
    width: 200px;
    height: 61px;
  }
}

.nav-link-class {
  color: white !important;
}
