$sidebar-bg-color: white;
$sidebar-color: black !default;

@import "~react-pro-sidebar/dist/scss/styles.scss";

.img-max {
  width: 44px;
  height: 44px;
}

@media only screen and (min-width: 576px) {
  .fixed-sidebar {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
  }
}

.active-menu-item {
  background-color: rgba(201, 31, 37, 0.1) !important;
  color: #c91f25;
  border-radius: 5px;
}

.admin-active-menu-item {
  background-color: rgba(155, 4, 230, 0.1) !important;
  color: #9b04e6;
  border-radius: 5px;
}

.menu-item {
  margin-left: 5%;
  margin-right: 5%;
}

.pro-inner-item:focus {
  color: #3a3b5c !important;
}

.pro-inner-item:hover {
  color: #c91f25 !important;
}

.admin-list:hover .hover-color-item {
  color: #c91f25 !important;
}

.admin-list:hover .hover-color-item-admin {
  color: #9b04e6 !important;
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .pro-item-content {
    margin-top: -12px;
    margin-bottom: 10px;
  }

  .menu-item {
    margin-left: 22%;
    margin-right: 30%;
    padding-left: 13%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .menu-item {
    margin-left: 0;
    margin-right: 0;
  }
}

.sidebar {
  height: 100vh !important; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100) !important;
  overflow-y: scroll !important;
}

@media (min-width: 991px) {
  .sidebar {
    overflow-y: auto !important;
  }
}

.cap-icon {
  margin-top: 11px !important;
}

.menubar-img {
  max-height: 100px;
}

.menubar-image-size {
  width: 200px;
  height: 100px;
}
