.login-class {
  background-color: #f2f2f2;
}

.forget-password {
  color: black;
}

.form-check-label {
  padding-top: 2px;
}
