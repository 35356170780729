.image-border {
  background-color: #f2f2f2;
  border-radius: 8px;
  width: 100px !important;
  height: 100px !important;
}

.card-contant {
  height: 65%;
}

.custom-control-input:focus ~ .custom-control-label::before {
  border-color: #f8971e !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #f8971e !important;
  background-color: #f8971e !important;
}
.custom-control-input:active ~ .custom-control-label::before {
  background-color: #f8971e !important;
  border-color: #f8971e !important;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f8971e !important;
}

input,
.StripeElement {
  width: 100%;
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

::placeholder {
  color: #aab7c4;
}

.error-label {
  color: #c91f25;
  margin-top: -22px;
}

.empty-label {
  padding-bottom: 2px;
}

.card-input-name {
  width: 100%;
  display: block;
  margin: 10px 0 20px 0 !important;
  padding: 10px 14px;
  font-size: 1em;
  -webkit-appearance: none;
  font-family: "Source Code Pro", monospace !important;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  -webkit-box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  margin-top: -4px;
  padding-top: 6px;
  padding-bottom: 8px;
  color: #424770 !important;
}

.heading-logo {
  height: 70px;
  width: 140px;
}

@media screen and (max-width: 576px) {
  .heading-logo {
    width: 100%;
    max-height: 70px !important;
    max-width: 140px !important;
  }
}

.payment-divider {
  height: 345px;
}

.payment-divider-large {
  height: 870px;
}

.payment-divider-medium {
  height: 500px;
}

.package-image-consumer {
  width: auto !important;
}

.package-font {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}

.package-image-wrapper {
  width: 100% !important;
  height: 100px !important;
}

@media screen and (max-width: 576px) {
  .tab-gap-for-border {
    margin-bottom: 50px !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  .tab-gap-for-border {
    margin-bottom: 210px !important;
  }
}

@media screen and (min-width: 992px) {
  .tab-gap-for-border {
    margin-bottom: 100px !important;
  }
}
