.pagination-row {
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 14px !important;
  font-weight: 400;
  height: 70px;
}

.toggle-button:hover,
.toggle-button:active {
  color: #ffffff !important;
  background-color: #1f2838 !important;
  border-color: #494f57 !important;
  box-shadow: none !important;
}

.toggle-button:focus {
  box-shadow: none !important;
}

.pagination-dropdown {
  min-width: 4.2rem !important;
}
