.contact-button {
  height: 44px;
  font-size: 14px;
  font-weight: 600;
  font-family: "lato";
}

@media only screen and (min-width: 375px) {
  .contact-button {
    width: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .contact-button {
    width: 120px;
  }
}
