.revenue-loder {
  display: flex;
  justify-content: center;
  padding-right: 20%;
}

.gross-sales-loder {
  display: flex;
  justify-content: center;
}

.react-datepicker__year-dropdown-container {
  padding-left: 21px;
}
