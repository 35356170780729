@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-input-placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif !important;
  font-weight: 400 !important;
}

:-ms-input-placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif !important;
  font-weight: 400 !important;
}

:-moz-placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif !important;
  font-weight: 400 !important;
}

::-moz-placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif !important;
  font-weight: 400 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.form-check-input {
  width: 20px;
  height: 20px;
}

.btn {
  font-weight: 500;
}

.min-vh-100 {
  min-height: -webkit-fill-available;
}

.border-3 {
  border-width: 3px !important;
}

.border-2 {
  border-width: 2px !important;
}

.rounded-corner {
  border-radius: 10px !important;
}

.rounded-corner-left {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.rounded-corner-right {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.header-title {
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.cursor-pointer {
  cursor: pointer;
}

.w-90 {
  width: 90%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.padding-top-2 {
  padding-top: 2px !important;
}

@media (min-width: 1200px) {
  .container-uxl {
    max-width: 1920px !important;
  }
}

@media (max-width: 768px) {
  .MuiPaginationItem-sizeLarge {
    min-width: 35px !important;
  }
}

@media (min-width: 1199.9px) {
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
}

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: var(--warning) !important;
}

.gross-sales-custom-pagination {
  margin-right: -15px !important;
  margin-left: -15px !important;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.rounded-10 {
  border-radius: 10px !important;
}

.back-arrow-button {
  background-color: rgba(201, 31, 37, 0.1) !important;
  color: var(--primary);
  padding: 6px;
  border-radius: 4px;
}

.custom-primary-btn:hover {
  color: var(--primary-dark) !important;
}

.muted-button {
  color: var(--muted);
  border-color: var(--muted);
}

.text-muted {
  color: var(--muted) !important;
}

.primary-button {
  color: var(--primary);
}

.secondary-button {
  color: var(--secondary);
}

.text-turncate-word-break {
  word-wrap: break-word;
}

.article-body {
  word-break: break-all;
}

.default-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='current'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 10px;
}
