.test-button {
  border-radius: 40px !important;
}

.flex-child-consumer-audio {
  max-width: 100px !important;
}

@media screen and (min-width: 768px) {
  .flex-child-consumer-audio {
    max-width: 200px !important;
  }
}

@media screen and (min-width: 992px) {
  .flex-child-consumer-audio {
    max-width: 300px !important;
  }
}

@media screen and (min-width: 1200px) {
  .flex-child-consumer-audio {
    max-width: 450px !important;
  }
}

@media screen and (min-width: 1400px) {
  .flex-child-consumer-audio {
    max-width: 600px !important;
  }
}

@media screen and (min-width: 1700px) {
  .flex-child-consumer-audio {
    max-width: 900px !important;
  }
}

.glow-on-test-button {
  border: none;
  outline: none;
  position: relative;
  color: white;
  z-index: 0;
}

.glow-on-test-button:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: 100%;
  height: 100%;
  animation: glowing 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 40px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
