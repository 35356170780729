.dropdown-toggle-filter-date::after {
  display: none !important;
}

.custom-menu-filter-date {
  left: -148% !important;
  width: 250% !important;
  top: 82% !important;
}

.custom-menu-filter-date:hover {
  color: black !important;
}

@media screen and (max-width: 576px) {
  .custom-menu-filter-date {
    top: 100% !important;
  }
}

.filter-button {
  -webkit-padding-before: 0.25rem !important;
  -webkit-padding-end: 0.25rem !important;
  -webkit-padding-after: 0.25rem !important;
  -webkit-padding-start: 0.25rem !important;
}

.filter-input:disabled {
  color: #484852 !important;
}
