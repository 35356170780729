.landing-page-body-class {
  background: white;
}

.customer-info-container {
  width: fit-content;
}

@media (min-width: 768px) {
  .whole-page h1 {
    font-size: 30px;
  }
  .whole-page p {
    font-size: 18px;
  }
}

@media (min-width: 1366px) {
  .whole-page h1 {
    font-size: 48px;
  }
  .whole-page p {
    font-size: 18px;
  }
}

.login-reg-button-background-landing-page {
  background-color: #e6e7ed !important;
}
