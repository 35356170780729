.dropdown-toggle-sales::after {
  display: none !important;
}

.custom-menu-sales {
  left: -270% !important;
  width: 370% !important;
}

.custom-menu-sales:hover {
  color: black !important;
}

.filter-label-sales {
  background-color: rgba(201, 31, 37, 0.05);
  border-radius: 45px;
  padding: 7px 12px;
}

@media screen and (max-width: 576px) {
  .gross-sales-border {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
}

@media screen and (max-width: 768px) {
  .custom-menu-sales {
    left: -250% !important;
    width: 350% !important;
  }
}

@media screen and (max-width: 576px) {
  .custom-menu-sales {
    left: -260% !important;
    width: 340% !important;
  }
}

.sales-filter-button {
  -webkit-padding-before: 0.25rem !important;
  -webkit-padding-end: 0.25rem !important;
  -webkit-padding-after: 0.25rem !important;
  -webkit-padding-start: 0.25rem !important;
}

.sales-filter-input:disabled {
  color: #484852 !important;
}
