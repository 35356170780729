.upload-image {
  position: absolute;
  margin-top: 90px;
  margin-left: -44px;
  border: 2px solid white;
  border-radius: 50%;
  padding: 6px;
  background-color: #f7f7f8;
}

.profile-image {
  max-width: 134px !important;
  max-height: 134px !important;
}
