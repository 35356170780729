.customer-package {
  font-weight: 500;
}

.header {
  border-bottom: 1px solid #f1f1f1;
  font-weight: 700;
}

.action-design {
  color: #2381ef;
  font-size: 14px;
}

@media screen and (min-width: 600px) {
  .customer-package {
    background-color: white;
    border-radius: 10px;
  }
}
