.button-style,
.change-password-style {
  font-size: 14px;
  height: 44px;
  font-weight: 700;
  border-radius: 5px;
}

.vertical-divider {
  margin-bottom: 70px;
  margin-right: -85px;
}

@media only screen and (min-width: 768px) {
  .change-password-style {
    width: 180px;
  }
}

.flex-child {
  max-width: 725px !important;
}
