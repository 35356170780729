.fixed-image {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
}

.fixed-button {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  padding-top: 20px;
  z-index: 3;
  background-color: white;
}

.button-shadow-none:focus {
  box-shadow: none !important;
}

@media (min-width: 1920px) {
  .container-uxl-auth {
    max-width: 1920px !important;
  }
}

@media screen and (max-width: 992px) {
  .custom-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #f7f7f8;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.3rem;
    margin-bottom: 10%;
  }

  .custom-card-margin {
    margin-left: 10%;
    margin-right: 10%;
  }

  .custom-card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }

  .heading {
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .custom-card-margin {
    margin-left: 0;
    margin-right: 0;
  }

  .heading {
    font-size: 23px !important;
    text-align: center;
  }
}

.logo-register {
  max-width: 200px !important;
  max-height: 100px !important;
}
