.firstrow-class {
  background-color: #dcdcdc;
}

.dashboard-button button {
  font-size: 18px !important;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
}

.category-button:focus {
  box-shadow: none !important;
}

.category-button:hover {
  background-color: white !important;
}

.category-svg-logo {
  width: 36px !important;
  height: 28px !important;
}
