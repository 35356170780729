.customer-panel {
  background-color: #f7f7f8;
}

.header-text,
.header-details {
  color: white !important;
}

.panel-button h1 {
  font-size: 18px;
}

@media screen and (min-width: 360px) {
  .fig-img {
    width: 219px;
    height: auto;
    border-radius: 8px;
    box-shadow: -20px 20px 0 0px rgba(248, 151, 30, 0.1);
  }

  .admin-img {
    width: 219px;
    height: auto;
    border-radius: 8px;
    box-shadow: 100px 20px 0 0px rgba(201, 31, 37, 0.1);
  }

  .fig-img img {
    width: 300px;
    height: 300px;
    border-radius: 8px;
    object-fit: cover;
    object-position: 2% 10%;
  }

  .admin-img img {
    width: 300px;
    height: 225px;
    border-radius: 8px;
    object-fit: cover;
    object-position: 2% 10%;
  }

  .info-header h1 {
    font-size: 30px;
  }

  .info-panel-image {
    margin-left: 0px;
  }

  .banner-img img {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .fig-img {
    width: 52%;
    height: 486px;
    border-radius: 8px;
    box-shadow: -40px 40px 0 0px rgba(248, 151, 30, 0.1);
  }

  .admin-img {
    width: 52%;
    height: 486px;
    border-radius: 8px;
    box-shadow: 335px 40px 0 0px rgba(201, 31, 37, 0.1);
  }

  .fig-img img {
    width: 180%;
    height: 600px;
    border-radius: 8px;
    object-fit: cover;
    object-position: 2% 10%;
  }

  .admin-img img {
    width: 180%;
    height: 486px;
    border-radius: 8px;
    object-fit: cover;
    object-position: 2% 10%;
  }

  .header-details p {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-weight: bolder;
  }

  .customer-panel-image {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .customer-panel-details {
    padding-top: 45px;
  }

  .info-panel-image {
    padding-top: 50px;
    padding-left: 0px;
  }
  .info-panel-details {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1024px) {
  .admin-img {
    width: 52%;
    height: 486px;
    border-radius: 8px;
    box-shadow: 235px 40px 0 0px rgba(201, 31, 37, 0.1);
  }
}

@media screen and (min-width: 1366px) {
  .fig-img {
    width: 399px;
    height: 375px;
    border-radius: 8px;
    box-shadow: -40px 40px 0 0px rgba(248, 151, 30, 0.1);
  }

  .admin-img {
    width: 399px;
    height: 375px;
    border-radius: 8px;
    box-shadow: 140px 40px 0 0px rgba(201, 31, 37, 0.1);
  }

  .fig-img img {
    width: 500px;
    height: 500px;
    border-radius: 8px;
    object-fit: cover;
    object-position: 2% 10%;
  }

  .admin-img img {
    width: 500px;
    height: 375px;
    border-radius: 8px;
    object-fit: cover;
    object-position: 2% 10%;
  }

  .info-header h1 {
    font-size: 48px;
  }

  .header-text {
    margin-top: 60px;
    margin-bottom: 50px;
    font-size: 64px !important;
  }

  .customer-panel-image {
    padding-left: 70px;
    margin-bottom: 10px;
  }

  .customer-panel-details {
    padding-left: 60px;
    padding-top: 45px;
  }

  .info-panel-image {
    margin-left: 68px;
    margin-top: -10px;
    text-align: center;
  }
}
