.dropzone {
  border-style: dashed;
  padding: 15px;
  border-radius: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.slected-file {
  max-width: 200px !important;
}

@media screen and (min-width: 576px) {
  .slected-file {
    max-width: 250px !important;
  }
}

@media screen and (min-width: 768px) {
  .slected-file {
    max-width: 350px !important;
  }
}

@media screen and (min-width: 992px) {
  .slected-file {
    max-width: 500px !important;
  }
}

@media screen and (min-width: 1200px) {
  .slected-file {
    max-width: 700px !important;
  }
}
