.header {
  border-bottom: 1px solid #f1f1f1;
  font-weight: 700;
}

@media screen and (min-width: 992px) {
  .consumer-list-for-admin {
    background-color: var(--white);
    border-radius: 10px;
  }
}

.token-ckeck-input {
  box-shadow: none !important;
  margin-left: -1rem !important;
}

.consumer-ckeck-input {
  box-shadow: none !important;
}

.consumer-ckeck-lebel {
  margin-top: 2px;
}
