$primary: #c91f25; // Red
$secondary: #9b04e6; // Violet
$light: #f7f7f8; // BG
$warning: #f8971e; // Orange
$muted: #8e8e9e; // Gray 2
$info: #3a3b5c; // Gray
$dark: #040528; // Dark Blue

:root {
  --muted: #8e8e9e;
  --primary-dark: #a81a1f; // color should be close to primary color
}

@import "../node_modules/bootstrap/scss/bootstrap.scss";
