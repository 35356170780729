.faq-search-box {
  box-shadow: none;
}

@media screen and (max-width: 557px) {
  .faq-search-icon {
    border: none !important;
    border-radius: 10px !important;
  }
}
